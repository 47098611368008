:root {
  --univslH: 40px;
}

.storage_container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap6);
  padding: var(--pd3);
  overflow-x: auto;
}

.storage_header {
  width: 100%;
}

.storage_body,
.storage_body_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--cl5);
}

.storage_body > i {
  position: sticky;
  left: 0;
  width: 100vw;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap6);
  transition: all 0.5s ease;

  > svg {
    cursor: pointer;
  }
}

.storage_body > i > svg.active {
  transform: scale(1.4);
  color: var(--cl26);
}

.storage_body > p {
  inline-size: 100%;
  height: var(--univslH);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs4);
  border-bottom: 1px solid #2227;
  background: var(--cl26) !important;
  color: var(--cl1);
  border-radius: 10px 10px 0 0;
}

.storage_body > p.df-aic-gap > span {
  display: flex;
  align-items: center;
  gap: var(--gap3);
}

.storage_body input {
  cursor: pointer;
}

._item-header > :where(label, p):hover {
  background: linear-gradient(to bottom, #333, var(--cl42) 5);
}

._item-header > :where(label, p) {
  border-right: 1px solid var(--cl37);
}

._item-header > label {
  transition: all 0.5s ease-in-out;
}

.storage_body__box {
  width: 100%;
  /* height: var(--univslH); */
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--gap6);
  overflow: hidden;
  transition: all 1s ease-in-out;
  border-bottom: 1px solid var(--cl38);
}

.storage_body__box.active {
  height: max-content;
  padding-bottom: 1%;
}

.storage_body_item {
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--cl38);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.storage_body_item > :where(:first-child, :nth-child(2)) {
  inline-size: var(--univslH);
  /* aspect-ratio: 1/1; */
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--cl38);
}

.storage_body_item > :first-child {
  inline-size: var(--univslH) !important;
}

._item-header {
  min-height: var(--univslH);
  background: var(--cl11);
  border-bottom: 1px solid var(--cl38);
}

.storage_body_item > :where(p, label) {
  inline-size: var(--data-line-size);
  padding: 0.2% calc(var(--univslH) / 3);
  display: flex;
  align-items: center;
  gap: var(--gap6);
}

.storage_body__box:last-child {
  border-bottom: none;
}

.storage_body_box > .storage_body_item > :where(p, label) {
  word-break: break-all;
}

.storage_body_box .storage_body_item:hover,
.storage_body_item.active {
  background: var(--cl11) !important;
  color: var(--cl1);
}

.storage_body_item > :where(p, label) u:hover {
  color: var(--cl26);
}

.storage_body__box .storage-body_inner_item {
  height: 0px;
  width: 65%;
  border: 1px solid var(--cl38);
  transition: all 0.3s ease-in-out;
}

.storage_body__box.active .storage-body_inner_item {
  height: max-content;
}

.storage-body_inner_item > .storage_body_item > p {
  border: none;
}

.inner_item > p {
  justify-content: flex-end;
}

.inner_item > :nth-child(2) {
  justify-content: flex-start;
}

.py-container {
  position: fixed;
  top: 100%;
  left: 0;
  height: 100vh;
  background: var(--cl60);
  flex-wrap: wrap;
  gap: 0 var(--gap1);
  z-index: 999999;
  transition: all 0.5s ease;
}

.py-container.active {
  top: 0;
}

.py-title {
  font-size: var(--fs4);
  color: var(--cl1);
}

.py-title > p {
  padding: 1% 2%;
}

.py-title > button {
  padding: 0 1%;
  background: transparent;
  color: var(--cl1);
  font-size: var(--fs4);
  border: none;
  cursor: pointer;
}

.py-title > button:hover {
  color: var(--cl26);
}

.py {
  box-shadow: none !important;
  background: var(--cl4) !important;
  max-height: 100% !important;
  border-radius: 0 15px 0 0 !important;
  gap: var(--gap6) !important;
}

.py > button {
  background: var(--cl26) !important;
}

.py-money-info {
  flex: 1;
  height: 100%;
  padding: 1.8% 2%;
  background: var(--cl4);
  color: var(--cl1);
  border-radius: 15px 0 0 0;
}

@media (max-width: 2000px) {
  .storage_container .short-hands_sort__box {
    display: none;
  }
}

@media (max-width: 900px) {
  .storage_container .short-hands_sort__box {
    display: flex;
  }
}

@media (max-width: 1150px) {
  .storage_body {
    width: 1010px;
  }

  .storage_body > p {
    justify-content: flex-start;
    padding-left: 2%;
  }

  .storage_body > p > span {
    position: sticky;
    left: 0;
  }
}
