:root {
  --primary-color1: #89b0ae;
  --primary-light-color1: #f5fbfb;
  --primary-bright-color1: #89b0ae65;

  --primary-color2: #d8e2dc;
  --primary-light-color2: #fcfefc;
  --primary-bright-color2: #d8e2dc69;

  --primary-color3: #ffd6ba;
  --primary-light-color3: #fff8ec;
  --primary-bright-color3: #ffd6ba6e;

  --primary-color4: #81b29a;
  --primary-light-color4: #c4eadf;
  --primary-bright-color4: #81b29a69;

  --primary-color5: #e29578;
  --primary-light-color5: #fdebd6;
  --primary-bright-color5: #e294786b;

  --primary-color6: #dde5b6;
  --primary-light-color6: #f8fbe9;
  --primary-bright-color6: #dde5b669;

  --primary-color7: #9ad1d4;
  --primary-light-color7: #f0f9f9;
  --primary-bright-color7: #9ad1d46e;

  --primary-color8: #f7d6bf;
  --primary-light-color8: #fff8ec;
  --primary-bright-color8: #f7d6bf6e;
}

.full-report-container {
  width: 100%;
  padding: var(--pd3);
  display: flex;
  flex-direction: column;
  gap: var(--gap3);
  color: var(--cl1);
}

.full-report-container > p {
  inline-size: 100%;
  line-height: 2.5;
  padding: 0 2%;
  background: var(--cl4);
  font-size: var(--fs5);
}

.full-report-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap6);
  font-size: var(--fs6);
}

.full-report-header:not(.jcc) {
  flex-wrap: wrap;
}

.full-report-header > p {
  position: relative;
  padding: 0.5% 2%;
  border-radius: 10px;
  border: 1px solid;
}

.full-report-header > p {
  background: var(--primary-bright-color1);
  color: var(--primary-light-color1);
  border-color: var(--primary-color1);
}

/* .full-report-header > p:nth-child(2) {
  background: var(--primary-bright-color2);
  color: var(--primary-light-color2);
  border-color: var(--primary-color2);
}

.full-report-header > p:nth-child(3) {
  background: var(--primary-bright-color3);
  color: var(--primary-light-color3);
  border-color: var(--primary-color3);
}

.full-report-header > p:nth-child(4) {
  background: var(--primary-bright-color4);
  color: var(--primary-light-color4);
  border-color: var(--primary-color4);
}

.full-report-header > p:nth-child(5) {
  background: var(--primary-bright-color5);
  color: var(--primary-light-color5);
  border-color: var(--primary-color5);
}

.full-report-header > p:nth-child(6) {
  background: var(--primary-bright-color6);
  color: var(--primary-light-color6);
  border-color: var(--primary-color6);
} */

.full-report-header > p:nth-child(7) {
  background: var(--primary-bright-color7);
  color: var(--primary-light-color7);
  border-color: var(--primary-color7);
}

.full-report-header > p:nth-child(8) {
  background: var(--primary-bright-color5);
  color: var(--primary-light-color5);
  border-color: var(--primary-color5);
}

.full-report-details {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: var(--gap3);
}

._details-content {
  grid-column: span auto;
  grid-row: span var(--grid-row-table-row);
  background: var(--cl67);
  border-radius: 15px;
}

._details-content > p {
  font-size: var(--fs4);
  inline-size: 100%;
  text-align: center;
  padding: 2% 3%;
}

.full-report-header > p.active::after {
  content: "";
  position: absolute;
  right: 50%;
  bottom: -8px;
  transform: translateX(50%) rotate(-180deg);
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 8px solid var(--primary-color7);
  border-radius: 100% 100% 0 0;
}

.full-report-header > p:nth-child(8).active::after {
  border-bottom: 8px solid var(--primary-color5);
}
