:root {
  --fs1: calc(36px + (48 - 36) * (100vw - 320px) / (1440 - 320));
  --fs2: calc(28px + (40 - 28) * (100vw - 320px) / (1440 - 320));
  --fs3: calc(24px + (32 - 24) * (100vw - 320px) / (1440 - 320));
  --fs4: calc(16px + (24 - 16) * (100vw - 320px) / (1440 - 320));
  --fs5: calc(16px + (20 - 16) * (100vw - 320px) / (1440 - 320));
  --fs6: calc(14px + (16 - 14) * (100vw - 320px) / (1440 - 320));
  --fs7: calc(13px + (14 - 13) * (100vw - 320px) / (1440 - 320));
  --fs8: calc(12px + (13 - 12) * (100vw - 320px) / (1440 - 320));
  --fs9: calc(15px + (18 - 15) * (100vw - 320px) / (1440 - 320));
  --pd1: 5% 7%;
  --pd2: 3.5% 5%;
  --pd3: 3%;
  --pd4: 4% 4% 5% 5%;
  --pd5: 1.3% 3%;
  --gap1: calc(15px + (30 - 15) * (100vw - 320px) / (1440 - 320));
  --gap2: calc(10px + (20 - 10) * (100vw - 320px) / (1440 - 320));
  --gap3: 15px;
  --gap4: calc(20px + (40 - 20) * (100vw - 320px) / (1440 - 320));
  --gap5: 5px;
  --gap6: 10px;
  --nwh: calc(65px + (80 - 65) * (100vw - 320px) / (1440 - 320));

  
  --cl1: #fff;
  --cl3: #f5f5f5;
  --cl4: #333;
  --cl5: #eee;
  --cl6: #ffffffd9;
  --cl7: #a1a1a1;
  --cl8: #80ed99;
  --cl9: #252525;
  --cl10: #3CE75B;
  --cl11: #3339;
  --cl12: #32ff09a2;
  --cl14: #ccc4;
  --cl15: #eee9;
  --cl16: #aaa5;
  --cl17: #00000090;
  --cl18: #555;
  --cl19: #999;
  --cl20: #33ff09;
  --cl21: #d82a0c;
  --cl22: #aaa;
  --cl23: #aaa5;
  --cl24: #444;
  --cl26: #787aff;
  --cl28: #eee4;
  --cl34: #222;
  --cl35: #666;
  --cl36: #ddd;
  --cl38: #ccc6;
  --cl39: #11b911;
  --cl40: #fc0;
  --cl41: #ff0000;
  --cl42: #ccc;
  --cl49: #454545;
  --cl50: #36cfc9;
  --cl51: #9e9e9e;
  --cl53: #111;
  --cl54: #5555;
  --cl57: #484848;
  --cl58: #38a3a5;
  --cl59: #353535;
  --cl60: #383838;
  --cl61: #282828;
  --cl62: #8882;
  /* --cl63: #f9e4bc; */
  /* --cl64: #fef69e; */
  /* --cl65: #3335; */
  /* --cl66: #353434; */
  /* --cl67: #404040; */
}

.w100 {
  width: 100%;
}

.df {
  display: flex;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.flc {
  flex-direction: column;
}

.jcsb {
  justify-content: space-between;
}

.gap5 {
  gap: var(--gap5);
}
