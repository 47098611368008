:root {
  --table-w-1: calc(50px + (100 - 50) * (100vw - 320px) / (1440 - 320));
  --table-w-2: calc(75px + (150 - 75) * (100vw - 320px) / (1440 - 320));
  --table-w-3: calc(100px + (200 - 100) * (100vw - 320px) / (1440 - 320));
}

.table_box {
  width: var(--table-w);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.table {
  position: relative;
  width: 100%;
  height: var(--table-w-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--cl36);
  border-radius: 12px;
  font-size: var(--fs4);
  font-weight: 500;
  color: var(--cl4);
  background: var(--cl5);
  z-index: 99;
}

.people {
  position: absolute;
  top: 3%;
  left: 3%;
  display: flex;
  align-items: center;
  color: var(--cl18);
}

.chair {
  width: calc(100% / var(--chair-w) - var(--gap6));
  aspect-ratio: 1/1;
  border: 1px solid var(--cl36);
  border-radius: 100%;
  margin: 7% 2%;
  background: var(--cl5);
}

.busy_chair {
  margin: -10% 2%;
  background: var(--cl18);
  border: 2px solid var(--cl26);
}

.busy_table {
  background: var(--cl18);
  border: 2px solid var(--cl26);
  color: var(--cl26);
}

.served_table {
  background: var(--cl26);
  border: 1px solid var(--cl26);
  color: var(--cl5);
}

.served_chair {
  margin: -10% 2%;
  background: var(--cl26);
  border: 1px solid var(--cl26);
}

@media (max-width: 500px) {
  .chair {
    width: calc(100% / var(--chair-w) - var(--gap6) * 1.2);
  }
}