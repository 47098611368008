:root {
  --space: 20px;
}

.navigation_container {
  width: 100%;
  padding: var(--pd3);
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap3);
}

.navigation_item {
  position: relative;
  width: calc(100% / 2 - calc(var(--gap3) / 2));
  aspect-ratio: 3/1.1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: all 0.6s ease-in-out;
  color: var(--cl5);
  overflow: hidden;
}

.navigation_item > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - var(--space) * 2);
  height: calc(100% - var(--space) * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs4);
  background: var(--cl4);
  opacity: 1;
  transition: opacity 0.3s ease-in-out, color 0.6s ease-in-out; /* Renk değişikliği için transition eklendi */
}

.navigation_item.active > p {
  color: var(--cl4);
  opacity: 0;
}

._item__piece-box {
  width: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  font: 0/0 a;
  gap: var(--space);
  transition: 0.5s ease-in-out;
  color: var(--cl3);
}

.navigation_item.active ._item__piece-box {
  width: 100%;
  height: 100%;
  color: var(--cl5);
  font: inherit;
}

._item__piece-box.active {
  animation: scaleTo 0.5s ease-in-out 0.5s forwards,
    fadeIn 0.5s ease-in-out 0.5s forwards;
}

.navigation_item__piece {
  width: calc(100% / 2 - calc(var(--space) / 2));
  height: calc(100% / 2 - calc(var(--space) / 2));
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--cl4);
  color: inherit;
  font-size: var(--fs6);
  text-align: center;
  word-wrap: break-word     ;
  transition: transform 0.5s ease-in-out, opacity 0.3s ease-in-out,
    color 0.2s ease-in-out;
  transform: scale(1);
}

._item__piece-box.active ._item__piece {
  transform: scale(0);
}

._item__piece-box.active .navigation_item__piece {
  animation: scaleFrom 0.5s ease-in-out 0.5s forwards,
    fadeIn 0.5s ease-in-out 0.5s forwards;
}

@keyframes scaleTo {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(0);
  }
}

@keyframes scaleFrom {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 500px) {
  .navigation_item {
    width: 100%;
  }
}
