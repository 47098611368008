:root {
  --obtn: 25px;
  --p-icon: 35px;
}

.home_page {
  padding: 0 1.5%;
}

.home_page.active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: var(--cl57);
  overflow-y: auto;
}

.home_page.active::-webkit-scrollbar {
  width: 0;
}

._orders {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  color: var(--cl1);
  font-size: var(--fs9);
  gap: var(--gap3);
}

.orders_body {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  grid-gap: var(--gap2);
  padding-bottom: 80px;
  color: var(--cl1);
  font-size: var(--fs9);
}

.orders_body.fullScreen {
  grid-template-columns: repeat(3, 1fr);
}

.orders_body > div {
  grid-column: span var(--grid-col);
  grid-row: span var(--grid-row);
}

.orders-header > b :last-child:hover {
  color: var(--cl20);
}

.orders-header,
.orders-footer {
  background: var(--cl4);
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.orders-header > span {
  display: flex;
  align-items: center;
  gap: var(--gap6);
  inline-size: 43%;
  line-height: 15px;
}

.orders-header > span > span {
  font-size: var(--fs4);
}

.orders-header > span > label {
  display: flex;
  flex-direction: column;
  inline-size: auto;
}

.orders-header > i {
  flex: 1;
}

.orders-header > *,
.orders-footer > * {
  z-index: 99;
}

.vhf {
  padding-bottom: 8%;
}

.home_page :where(.css-dev-only-do-not-override-1faqezx).ant-btn-default {
  background: var(--cl24);
}

.orders-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -1.5%;
  width: 103%;
  height: 100%;
  background: inherit;
}

.orders-header :where(.css-dev-only-do-not-override-1faqezx).ant-segmented {
  height: 40px;
}

.orders-header
  :where(.css-dev-only-do-not-override-1faqezx).ant-segmented
  .ant-segmented-item-label {
  min-height: 30px;
  line-height: 30px;
  font-size: var(--fs4);
  padding-top: 5px;
}

.orders-header {
  position: sticky;
  top: var(--nwh);
  width: 100%;
  z-index: 999;
}

.home_page.active .orders-header {
  top: 0;
}

.orders-header > b {
  inline-size: auto;
  font-size: var(--fs5);
  padding: 0.7% 0 0.6% 1.2%;
  cursor: pointer;
}

.orders-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - var(--nwh) + 1px);
  z-index: 999;
  padding: 0.5% 1%;
  justify-content: space-between;
}

.home_page.active .orders-footer {
  width: 100%;
}

.orders-footer :where(.css-dev-only-do-not-override-1oj2w71).ant-tag-checkable {
  line-height: 40px;
  padding: 0 20px;
  background-color: var(--cl24) !important;
  border-color: transparent;
  color: var(--cl1) !important;
  font-size: var(--fs5);
}
.orders-footer
  :where(.css-dev-only-do-not-override-1oj2w71).ant-tag-checkable-checked {
  background-color: var(--cl26) !important;
  color: var(--cl1) !important;
}

.order_item {
  /* padding: var(--pd5); */
  background: var(--cl4);
  border-radius: 15px;
}

.order_item,
.order_item-body > figcaption {
  width: 100%;
  display: flex;
  place-items: center;
  flex-wrap: wrap;
  gap: var(--gap6);
}

.order_item span {
  text-transform: capitalize;
}

.order_item > :last-child {
  border: none;
}

.order_item-body > figcaption {
  position: relative;
  gap: var(--gap2);
  border-bottom: 1px solid var(--cl16);
  padding-bottom: 2%;
}

.order_item-body > figcaption > i {
  position: absolute;
  top: 0;
  left: 0;
  width: 85%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.order_item-body > :last-child {
  border: none;
}

.order_item > div {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 1.5;
  border-bottom: 1px solid var(--cl22);
  font-size: var(--fs6);
}

.order_item_header {
  width: 100%;
  padding: 1% 2%;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  background: var(--cl4);
  border: none;
  border-radius: 15px 15px 0 0;
}

.order_item_header > p {
  display: flex;
  align-items: center;
  gap: var(--gap6);
  font-size: var(--fs6);
}

/* .order_item > div > :nth-child(2) {
  flex: 1;
} */

.order_item-body {
  width: 100%;
  padding: var(--pd5);
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap3);
}

.btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  gap: var(--gap3);
}

.btn_box > button {
  border-radius: 50%;
  font-size: var(--fs5);
}

.order_item-body > figcaption > pre {
  width: 35%;
}

.order_item-body > figcaption > pre > :last-child {
  color: var(--cl22);
}

.order_item-body > figcaption > span {
  text-align: end;
  flex: 1;
}

.order_stution {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: var(--gap6);
}

.order_stution > button,
.btn_box > button,
.qty {
  width: calc(var(--obtn) * 1.2);
  height: calc(var(--obtn) * 1.2);
  display: flex;
  place-items: center;
  justify-content: center;
  border: none;
  color: var(--cl1);
  opacity: 0.7;
}

.order_stution > button,
.qty {
  width: var(--obtn);
  height: var(--obtn);
  border-radius: 7px;
  font-size: var(--fs6);
  background: transparent;
}

.qty {
  background: var(--cl26);
  font-weight: 600;
}

.order_stution > button:hover,
.btn_box > button:hover {
  opacity: 1;
}

.order_stution > button:active,
.btn_box > button:active {
  opacity: 0.7;
}

.btn_box > :first-child {
  background: var(--cl41);
}

.order_stution > button {
  font-size: var(--fs3);
  color: var(--cl41);
}

.btn_box > :last-child {
  background: var(--cl20);
}

/*========= order_footer =========*/

.order_footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3%;
  gap: var(--gap3);
}

.order_footer > button {
  margin-top: -8%;
  height: 45px;
  padding: 0 2.7%;
  border-radius: 10px;
  border: none;
  color: var(--cl1);
  background: var(--cl58);
  font-size: var(--fs6);
  letter-spacing: 0.05rem;
  transition: all 0.3s ease-in-out;
  z-index: -9;
}

.time {
  inline-size: 100%;
  text-align: end;
  padding: 0 2.7% 1%;
  font-size: var(--fs8);
  letter-spacing: 1px;
}

.accepted {
  transform: translateX(-500%);
  transition: all 1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

.cirscle_container * {
  color: var(--cl5);
}

.swipe-pages {
  display: flex;
  align-items: center;
  gap: var(--gap6);
}

.swipe-pages > span {
  width: var(--p-icon);
  height: var(--p-icon);
  font-size: var(--fs4);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--cl26);
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.swipe-pages > span.active {
  transform: scale(1.2);
}

@media (max-width: 600px) {
  .orders_body {
    grid-template-columns: repeat(1, 1fr);
  }

  .orders_body.fullScreen {
    grid-template-columns: repeat(2, 1fr);
  }

  .home_page.active {
    padding: var(--pd3);
  }
}

@media (max-width: 500px) {
  .orders_body.fullScreen {
    grid-template-columns: repeat(1, 1fr);
  }
}
