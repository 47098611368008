:root {
  --icon-w-h: 38px;
}

.changed_tool {
  width: 100%;
  height: 100%;
  padding: 1% 2%;
  display: flex;
  background: var(--cl60);
}

.changed_tool > input {
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  border-radius: 0;
  color: var(--cl5);
  font-size: var(--fs6);
}

.inventory_header {
  display: flex;
  align-items: center;
  gap: var(--gap3);
  font-size: var(--fs3);
}

.inventory_header span {
  font-size: var(--fs5);
}

.inventory_header
  :where(.css-dev-only-do-not-override-1qxsxy1).ant-select
  .ant-select-arrow
  svg {
  font-size: var(--fs8);
}

.inventory_header
  :where(
    .css-dev-only-do-not-override-1qxsxy1
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  font-size: var(--fs7);
}

.inventory-item > p > span {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.inventory-item > p > span > del {
  color: var(--cl41);
}

.inventory_btn-box {
  display: flex;
  align-items: center;
  gap: var(--gap6);
}

.inventory_btn-box > :where(button, div) {
  position: relative;
  width: var(--icon-w-h);
  height: var(--icon-w-h);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs5);
  color: var(--cl1);
  background: var(--cl26);
  border: none;
  border-radius: 50%;
}

.inventory_btn-box > :where(button, div) > svg {
  font-size: var(--fs4);
}

.inventory-history {
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.history-box {
  position: relative;
  height: auto;
}
  
.ticket {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  background: var(--cl26);
  opacity: 0;
  transform: rotate(45deg) translate(-50%, 0%);
  transition: opacity 0.7s ease-in;
  z-index: -9;
}

.inventory-history.active .ticket {
  top: 135%;
  opacity: 1;
  transition: opacity 0.7s ease-in;
  z-index: 99999;
}

._history-body {
  position: absolute;
  top: 135%;
  right: -50%;
  width: 300px;
  height: 0px;
  padding: 0;
  /* display: none; */ /* Remove this line */
  flex-direction: column;
  border-radius: 5px;
  background: var(--cl26);
  z-index: 9999;
  overflow: hidden;
  box-shadow: 0px 9px 30px rgba(19, 18, 18, 0.658);
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Updated transition properties */
  opacity: 0;
}

.inventory-history.active ._history-body {
  height: auto;
  max-height: 300px;
  padding: 5px 15px;
  /* display: flex; */ /* Remove this line */
  transition: height 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Updated transition properties */
  overflow: unset;
  overflow-y: auto;
  opacity: 1;
}

.inventory-history.active ._history-body::-webkit-scrollbar {
  width: 3px;
  border-radius: 3px;
  background: var(--cl3);
}

.inventory-history.active ._history-body::-webkit-scrollbar-thumb {
  background: var(--cl5);
  border-radius: 3px;
}

._history-body > p {
  position: relative;
  width: 100%;
  padding: 3% 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs6);
  transition: all 0.3s ease;
  cursor: pointer;
  border-bottom: 1px solid var(--cl15);
  color: var(--cl42);
}

._history-body > p::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -14px;
  transform: translate(0%, -50%);
  width: 3px;
  height: 80%;
  background: transparent;
  transition: all 0.3s ease;
}

._history-body > p:last-child {
  border-bottom: none;
}

._history-body > p:hover {
  color: var(--cl1);
}

._history-body > p:hover::after {
  background: var(--cl1);
}

.inventory_body .worker > :first-child {
  padding-top: 0;
}

@media (max-width: 768px) {
  .inventory_header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .inventory_header > select {
    width: 100%;
    height: 30px;
    border-radius: 5px;
  }
}
