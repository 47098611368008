:root {
  --icon-wh: 25px;
  --display-i-h: 50px;
  --item-tool-h: 35px;
}

.my-order-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: var(--cl1);
}

.my-order-main > p {
  inline-size: 100%;
  padding: 1.5% 2%;
  font-size: var(--fs3);
}

.my-order-display-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow-x: auto;
}

.my-order-display-box::-webkit-scrollbar {
  display: none;
}

.my-order-display {
  width: calc(100% / 3);
  height: 100%;
  padding: 0 1%;
  display: flex;
  flex-direction: column;
  gap: var(--gap6);
  border-right: 1px solid var(--cl61);
}

.my-order-display:last-child {
  border: transparent;
}

.my-order-display > p {
  padding: 1% 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs5);
  background: var(--cl26);
  border-radius: 6px;
}

.my-order-display-item {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  background: var(--cl54);
  border-radius: 9px;
}

._item-frame {
  width: 100%;
  height: var(--display-i-h);
  padding: 0 var(--pd3);
  display: flex;
  align-items: center;
  gap: var(--gap6);
  font-size: var(--fs6);
  color: var(--cl1);
  background: var(--cl24);
  border: 1px solid var(--cl61);
  border-radius: 9px;
  transition: all 0.3s ease;
}

._item-frame:hover {
  border: 1px solid var(--cl5);
}

._item-frame > :where(p, span) {
  inline-size: var(--my-order-w);
  border-right: 1px solid var(--cl61);
}

._item-frame > :where(:nth-child(4), :nth-child(5)) {
  border-right: transparent;
}

._item-frame > p > i {
  width: var(--icon-wh);
  height: var(--icon-wh);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--fs5);
  color: var(--cl34);
  border-radius: 50%;
}

._item-tool {
  width: 100%;
  height: 0px;
  /* padding: 0 var(--pd3); */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  overflow: hidden;
}

.my-order-display-item.active ._item-tool {
  height: calc(var(--item-tool-h) * var(--item-tool-c));
  transition: all 0.3s ease;
}

._item-tool > div {
  width: 100%;
  height: var(--item-tool-h);
  padding: 0 3%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs7);
}

._item-tool > div:hover {
  background: var(--cl49);
  border-radius: 9px;
}
.total_price {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--nwh);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--cl53);
  font-size: var(--fs4);
  border-top: 1px solid var(--cl42);
}

@media (max-width: 768px) {
  .my-order-display {
    width: 100%;
  }
  .total_price {
    bottom: var(--nwh);
  }
  ._item-frame > :where(p, span) {
    text-align: center;
  }
}
