.check_main_box {
  width: 100%;
  height: 70vh;
  padding-top: calc(var(--nwh) * 4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.check_body {
  width: 400px;
  min-height: 500px;
  padding: var(--pd3) var(--pd3) calc(var(--pd3) * 2);
  display: flex;
  flex-direction: column;
  background: var(--cl1);
  gap: var(--gap3);
  color: var(--cl53);
}

.check_body_header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.check_body_header > :nth-child(1) {
  inline-size: 100%;
  font-size: var(--fs3);
  text-align: center;
}

.check_body_header > :nth-child(2) {
  inline-size: 100%;
  font-size: var(--fs4);
  text-align: center;
}

.check_body_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap5);
}

.check_body_box p {
  inline-size: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.4;
  font-size: var(--fs5);
}

.check_body_box > span {
  inline-size: 100%;
  text-align: center;
  font-size: var(--fs6);
  letter-spacing: 0.05rem;
}

.check_product > pre > :last-child {
  font-size: var(--fs6);
  padding-left: 20%;
}

.lines {
  width: 100%;
  border-bottom: 1px dashed var(--cl54);
  line-height: 1.8;
}

.lined {
  width: 100%;
  border-bottom: 1px dotted var(--cl54);
  line-height: 1.8;
}
