.storage_body_report {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: var(--pd3);
  border-top: 1px solid var(--cl37);
  gap: var(--gap5) var(--gap1);
}

.report_item {
  width: calc(100% / 2 - calc(var(--gap1) / 2));
  min-height: 360px;
  padding: calc(var(--pd3) / 2);
  display: flex;
  flex-direction: column;
  gap: var(--gap6);
  border: 1px solid var(--cl37);
  background: var(--cl60);
}

._header {
  inline-size: 100%;
  text-align: center;
  font-size: var(--fs4);
}

._body__item {
  width: 100%;
  padding: 2%;
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--cl37);
  background: var(--cl54);
}

._body__item > p {
  inline-size: 30%;
}

._body__item_details {
  width: 70%;
  display: flex;
  flex-direction: column;
}

._details__item {
  width: 100%;
  padding-bottom: 1%;
  display: flex;
  align-items: center;
  gap: var(--gap3);
  border-bottom: 1px solid var(--cl38);
}

.__item-info-piece {
  border-bottom: 1px dotted var(--cl38);
}

._details__item:last-child {
  border: none;
  padding: 0;
}

._details__item > :first-child {
  flex: 1;
}

.report_daily {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.report_daily > i {
  flex: 1;
  border-bottom: 1px dashed var(--cl38);
}

.big_fs {
  font-size: var(--fs4);
}

.pdt {
  padding-top: var(--gap3);
}
