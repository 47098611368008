.restaurant_box {
  width: 100%;
  padding: var(--pd3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add_reastaurant {
  width: 700px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap1);
}

.add_img {
  width: 700px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: linear-gradient(
    to right bottom,
    var(--cl4),
    #9511ec,
    #ec11da,
    var(--cl4)
  );
  cursor: pointer;
  overflow: hidden;
  position: relative;
  z-index: 99;
}

.add_img::after {
  content: "";
  position: absolute;
  width: 99.5%;
  height: 98.5%;
  background: var(--cl18);
  z-index: -99;
  border-radius: 28px;
}

.add_img > input {
  display: none;
}

.add_img > svg {
  font-size: 200px;
  color: var(--cl5) 7;
}

.add_reastaurant > input,
.label {
  position: relative;
  width: calc(50% - 15px);
  height: 50px;
  padding: 0 2%;
  border-radius: 10px;
  background: none;
  border: 1px solid var(--cl4);
  color: var(--cl5);
  font-size: var(--fs5);
}

.label {
  display: flex;
  align-items: center;
}

.label > input {
  height: 100%;
  flex: 1;
  background: none;
  border: none;
  color: var(--cl5);
  font-size: var(--fs5);
}

.label > span {
  cursor: pointer;
}

.add_reastaurant > :nth-child(2) {
  width: 100%;
  text-align: center;
}

.add_reastaurant > button {
  position: relative;
  width: calc(50% - 15px);
  height: 50px;
  background: linear-gradient(
    to right bottom,
    var(--cl4),
    #9511ec,
    #ec11da,
    var(--cl4)
  );
  color: var(--cl5);
  font-size: var(--fs5);
  border: none;
  border-radius: 10px;
  z-index: 99;
}

.add_reastaurant > button::after {
  content: "";
  position: absolute;
  top: 5%;
  left: 1%;
  width: 98%;
  height: 90%;
  background: var(--cl18);
  border-radius: 9px;
  z-index: -9;
}

.add_reastaurant > button:active::after {
  background: var(--cl24);
}

.add_reastaurant > input[type="submit"]:active {
  background: var(--cl5) 3;
}

.add_reastaurant > input::placeholder,
.label > input::placeholder {
  color: var(--cl5) 3;
}

.selected_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delivery_time {
  width: calc(50% - 15px);
  height: 50px;
  display: flex;
  flex-direction: column;
}

.delivery_time > p {
  margin-top: -25px;
  font-size: var(--fs6);
  color: var(--cl5);
  line-height: 1.5;
}

.delivery_time > label,
.delivery_time > div {
  width: 100%;
  height: 50px;
  padding: 0.5% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--cl4);
  border-radius: 10px;
  font-size: var(--fs6);
  color: var(--cl5);
}

.delivery_time > label > input {
  width: 65px;
  height: 35px;
  border: 1px solid var(--cl48);
  background: none;
  border-radius: 10px;
  text-align: center;
  color: var(--cl5);
  padding: 0 2%;
}

.delivery_time > div {
  justify-content: space-around;
}

.delivery_time > div > :first-child {
  border-right: 1px solid var(--cl4);
}

.delivery_time > div > label {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delivery_time > div > label > input {
  width: 20px;
  height: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.delivery_time > label > p {
  padding: 0 10% 0 1%;
}
