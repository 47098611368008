:root {
  --row-table-height: 40px;
  --title-w: 250px;
}

.row-table {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  color: var(--cl1);
}

.row-table > p {
  inline-size: 100%;
  display: flex;
}

.row-table > p > span {
  inline-size: calc(var(--title-w) + ((var(--row-table-height) * 1.1) * var(--extra-w)));
  padding: 0.5%;
  text-align: center;
  background-color: var(--cl26);
  border-radius: 10px 10px 0 0;
}

.row-table > .row-table-item {
  width: var(--title-w);
  background: linear-gradient(90deg, var(--cl59), var(--cl54));
  > :where(p, label) {
    padding: 0 2% 0 4% !important;
  }
}

.row-table .row-table-item {
  display: flex;
  flex-direction: column;
}

.row-table .row-table-item > :where(p, label) {
  height: var(--row-table-height);
  padding: 0 2%;
  display: flex;
  align-items: center;
  border-right: 1px solid var(--cl14);
  border-bottom: 1px solid var(--cl14);
}

/*.row-table-box > .row-table-item > :where(p, label) {
  padding: 0 6%;
}*/

.row-table .row-table-item:last-child > :where(p, label) {
  border-right: none;
}

.row-table .row-table-item > :nth-child(1) {
  height: 100px;
}

.row-table-box {
  width: calc(100% - var(--title-w));
  display: flex;
  align-items: flex-start;
}

.row-table-box > .row-table-item > :nth-child(1) > p {
  transform: rotate(-90deg);
  font-size: var(--fs8);
}

.row-table-box .row-table-item > :where(p, label) {
  width: calc(var(--row-table-height) * 1.5);
  justify-content: center;
}
