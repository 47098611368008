:root {
  --wh: 45px;
  --lnh: 2;
}

.order_box {
  width: 100%;
  height: 100%;
  padding: var(--pd3);
  display: flex;
  flex-direction: column;
  color: var(--cl1);
  background: var(--cl57);
  gap: var(--gap);
}

.order_box > p {
  font-size: var(--fs2);
  border-bottom: 1px solid var(--cl42);
  line-height: 1.7;
}

.order_box__item {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap3);
  border-bottom: 1px solid var(--cl14);
  line-height: var(--lnh);
}

.order_box__item > :first-child {
  inline-size: 30%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.order_box__item > :nth-child(2) {
  inline-size: 10%;
}

.order_box__item :where(p, span, i) {
  font-size: var(--fs4);
}

.order_box__item > span {
  text-align: end;
  flex: 1;
}

.order_box > :nth-last-child(2):not(:first-child) {
  border-bottom: none;
}

.item_status {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cl1);
}

.item_status span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.item_status svg {
  font-size: var(--fs3);
}

.item_status > span > :nth-child(1) {
  z-index: 9;
  color: var(--cl20);
}

.item_status > span > :nth-child(2) {
  margin-left: -10px;
  color: var(--cl40);
  font-size: var(--fs2);
}

.order-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - calc(var(--nwh) - 1px));
  height: var(--iwh2);
  padding: 0 var(--pd3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--cl66);
  color: var(--cl1);
  border-top: 1px solid var(--cl38);
  z-index: 999;
}

.order-footer > button {
  width: calc(100% / 3 - var(--gap2));
  height: 40px;
  background: none;
  border: 1px solid var(--cl38);
  border-radius: 20px;
  color: var(--cl1);
  font-size: var(--fs5);
  letter-spacing: 1px;
}

@media (max-width: 600px) {
  .order-footer {
    bottom: var(--nwh);
    width: 100%;
    z-index: 9999999;
  }

  .order-footer > button {
    font-size: var(--fs6);
  }
}

@media (max-width: 450px) {
  .order-footer > button {
    width: calc(100% / 3 - var(--gap6));
    border-radius: 12px;
  }
}

@media (max-width: 360px) {
  .order-footer > button {
    width: calc(100% / 3 - var(--gap5));
    border-radius: 8px;
  }
}
