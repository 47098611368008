:root {
  --pw: 300px;
}

.payment_container {
  position: relative;
  width: 100%;
  padding: var(--pd3);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap2);
  color: var(--cl1);
}

.payment_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment_header > p {
  inline-size: 100%;
  font-size: var(--fs3);
}

.payment_header select {
  width: 200px;
  height: 40px;
  background: none;
  border: 1px solid var(--cl36);
  border-radius: 10px;
  padding: 0 1%;
  font-size: var(--fs5);
  color: var(--cl1);
  outline: none;
}

.payment_item {
  position: relative;
  width: calc(var(--pw) - calc(var(--gap2) - 5px));
  aspect-ratio: 1/1.5;
  padding: 1% 1.5% 1.5%;
  display: flex;
  flex-direction: column;
  background: var(--cl4);
  border-radius: 10px;
  line-height: 1.4;
}

.payment_tick {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 0;
  height: 0;
  border: 10px solid var(--cl39);
  border-block-end: 5px solid transparent;
  border-inline-start: 25px solid transparent;
  border-start-end-radius: 7px;
}

.payment_tick.not_paid {
  border: 10px solid var(--cl40);
  border-block-end: 5px solid transparent;
  border-inline-start: 25px solid transparent;
}

.payment_item-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs7);
  text-transform: capitalize;
  gap: var(--gap1);
  z-index: 99;
}

.payment_item > p {
  display: flex;
  align-items: center;
  gap: var(--gap6);
  z-index: 99;
}

.payment_item > :nth-child(2) {
  font-size: var(--fs6);
}

.payment_item > :nth-child(3) > :last-child {
  font-size: var(--fs7);
  color: var(--cl5);
  font-weight: 500;
}

.p_data_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  border-bottom: 1px solid var(--cl14);
  flex: 1;
}

.p_data_box > p {
  color: var(--cl5) !important;
  font-size: var(--fs6);
  border-bottom: 1px solid var(--cl14);
}

.p_data_box :where(p, li) {
  inline-size: 100%;
  line-height: 2;
  display: flex;
  align-items: center;
  gap: var(--gap6);
  color: var(--cl15);
}

.p_name {
  flex: 1;
}

.p_btn__box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap6);
}

.p_btn__box > button {
  width: calc(100% / 2 - calc(var(--gap6) / 2));
  padding: 2% 0;
  border-radius: 5px;
  background: none;
  border: 1px solid var(--cl5);
  color: var(--cl1);
  font-size: var(--fs6);
}

@media (max-width: 768px) {
  :root {
    --pw: 50%;
  }
}

@media (max-width: 500px) {
  :root {
    --pw: 100%;
  }

  .payment_item {
    width: var(--pw);
    aspect-ratio: 1/1.3;
    padding: 4% 6% 6%;
    margin: 2% 5% 0;
  }
}
