:root {
  --univh: 30px;
}

.wdfaic {
  width: 100%;
  display: flex;
  align-items: center;
}

.u-control-container {
  position: fixed;
  left: var(--nwh);
  bottom: -100dvh;
  width: calc(100% - var(--nwh));
  height: calc(100dvh - var(--nwh));
  padding: var(--pd3);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap1);
  overflow: hidden;
  background: var(--cl57);
  transition: all 0.3s ease-in-out;
  z-index: 999;
}

.u-control-container.open {
  bottom: 0;
}

.u-control_form_box {
  flex-wrap: wrap;
  gap: var(--gap6);
  color: var(--cl1);
  z-index: 99;
}

.u-control_form_box > input {
  width: 15%;
}

/* =========== universal control box ============ */
.u-control_add_box {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap3);
  color: var(--cl1);
}

.section_u {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap6);
}

.add_box__header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap6);
  color: var(--cl1);
}

._header_parts {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--cl35);
  border-radius: 10px;
  overflow: hidden;
  background: var(--cl35);
  gap: 1px;
}

._header_parts > span {
  width: 50%;
  text-align: center;
  padding: 1.5% 2%;
  font-size: var(--fs6);
  background: var(--c24);
  color: var(--cl42);
  transition: all 0.2s ease;
  cursor: pointer;
}

._header_parts .active {
  color: var(--cl1);
  background: var(--cl4);
}

._header_parts .passive {
  background: var(--cl49);
}

._header_parts .passive:hover {
  background: var(--cl4);
}

.add_box__header input {
  width: 100%;
  padding: 0 3%;
}

.add_box__header :where(input, select) {
  height: var(--univh);
  border: none;
  border-radius: 5px;
  background: var(--cl4);
  font-size: var(--fs6);
  letter-spacing: 1px;
  color: var(--cl3);
}

.add_box__header select {
  width: calc(100% / 2 - calc(var(--gap6) / 2));
  padding: 0 2%;
}

.u-control_product_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cl38);
  overflow-y: auto;
}

.u-control_product_box::-webkit-scrollbar {
  display: none;
}

.u-control_product_box > :first-child {
  position: sticky;
  top: 0;
}

.u-control_product_box > :last-child {
  margin-bottom: 45px;
}

.product_box_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.product_box_body > :last-child {
  border-bottom: none;
}

.product_box_item {
  width: 100%;
  height: calc(var(--univh) + 5px);
  display: flex;
  align-items: center;
  font-size: var(--fs7);
  background: var(--cl4);
  color: var(--cl1);
  border-bottom: 1px solid var(--cl38);
}

.product_box_item > :first-child {
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_box_item > :where(p, label) {
  inline-size: var(--data-line-size);
  padding: 0 calc(var(--univh) / 3);
  height: 100%;
  display: flex;
  align-items: center;
  gap: var(--gap6);
  text-align: center;
  border-right: 1px solid var(--cl38);
}

.product_box_item > :last-child {
  border-right: none;
}

.product_box_body .product_box_item {
  background: transparent;
}

.product_box_body :where(p, label) {
  border: none;
}

.product_box_item.active,
.product_box_body .product_box_item:hover {
  background: var(--cl24);
}

.section_u {
  height: 92%;
}

.section_u > p {
  inline-size: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs5);
}

.section_u > p > button {
  padding: 1% 2%;
  border: none;
  border-radius: 5px;
  background: var(--cl26);
  color: var(--cl1);
  font-size: var(--fs8);
}

.product_box_item > p > :where(input, select) {
  width: 80%;
  padding: 0% 5%;
  text-align: end;
}

.product_box_item > p > select {
  width: 95%;
}

/* =========== universal control calculator box =========== */
.u-control_calc_box {
  width: calc(40% - var(--gap1));
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--gap6);
}

.u-control_calc_box > p {
  inline-size: 100%;
  font-size: var(--fs5);
  color: var(--cl1);
}

.u-control_calc_body {
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  gap: var(--gap6);
  border: 1px solid var(--cl38);
  overflow-y: auto;
}
/* 
.u-control_calc_body .product_box_body {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.u-control_calc_body .product_box_body::-webkit-scrollbar {
  display: none;
} */

.u-control_calc_body .product_box_item {
  overflow-x: auto;
  font-size: var(--fs8);
}

.u-control_calc_body .product_box_item::-webkit-scrollbar {
  display: none;
}

.u-control_action__box {
  position: fixed;
  bottom: -100%;
  right: 30px;
  display: flex;
  align-items: flex-end;
  gap: var(--gap6);
  transition: all 0.5s ease;
  z-index: 999;
}

.u-control_action__box.active {
  bottom: 20px;
}

.u-control_action__box > button {
  height: calc(var(--univh) + 10px);
  width: calc(var(--univh) + 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 25px;
  background: var(--cl4);
  color: var(--cl1);
  font-size: var(--fs6);
  overflow: hidden;
  transition: all 0.3s ease;
}

.u-control_action__box > button:hover {
  border: 1px solid var(--cl35);
}

.u-control_action__box > figure {
  width: calc(var(--univh) + 10px);
  height: calc(var(--univh) + 10px);
  cursor: pointer;
}

.u-control_action__box > figure > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_box_footer {
  height: calc(var(--univh) * 3);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.product_box_footer > p {
  inline-size: 100%;
  height: var(--univh);
  padding: 0 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs6);
  color: var(--cl1);
  border-top: 1px solid var(--cl38);
  background: var(--cl4);
}

@media (max-width: 1200px) {
  .u-control-container {
    overflow-y: auto;
  }

  .u-control_add_box {
    width: 100%;
    height: 80%;
  }

  .section_u {
    height: 100%;
  }

  .u-control_calc_box {
    width: 100%;
    height: auto;
    padding-bottom: 8%;
  }

  .u-control_calc_box .u-control_calc_body {
    height: 500px;
  }
}

@media (max-width: 900px) {
  .u-control_form_box > input {
    width: 20%;
  }
  .u-control_add_box {
    height: 70%;
  }
  .product_box_item > p > :where(input, select) {
    width: 95%;
  }
}

@media (max-width: 600px) {
  .u-control-container {
    left: 0;
    bottom: -100dvh;
    width: 100%;
    height: calc(100dvh - calc(var(--nwh) * 2));
  }

  .u-control_action__box.active {
    bottom: calc(var(--nwh) + 10px);
  }
  .u-control-container.open {
    bottom: var(--nwh);
  }
  .u-control_add_box {
    height: 500px;
    width: 100vw;
    overflow-x: auto;
  }
  .u-control_calc_box .u-control_calc_body {
    height: 400px;
    padding-bottom: 50px;
  }
  .u-control_form_box > input {
    width: 35%;
  }
}
